import { BotDetectionResult, load as botdLoad } from '@fingerprintjs/botd';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, RemoteDependencyData } from '@microsoft/applicationinsights-web';
import React, { Component, PropsWithChildren } from 'react';

interface Props {
    connectionString?: string,
    appVersion?: string
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<PropsWithChildren<Props>> {
    static reactPlugin = new ReactPlugin();
    static appInsights?: ApplicationInsights;
    static botdValue: BotDetectionResult;

    constructor(props: PropsWithChildren<Props>) {
        super(props);
        if (!TelemetryProvider.appInsights) {
            TelemetryProvider.appInsights = new ApplicationInsights({
                config: {
                    connectionString: props.connectionString,// <local> if de env, to do manual tracks if needed
                    instrumentationKey: !props.connectionString ? "<local>" : undefined,
                    disableTelemetry: false,
                    enableAutoRouteTracking: true,
                    extensions: [TelemetryProvider.reactPlugin],
                    enableCorsCorrelation: true,
                    distributedTracingMode: DistributedTracingModes.W3C,
                    correlationHeaderExcludedDomains: [
                        '*.googleapis.com',
                        '*.aptrinsic.com'
                    ]
                },
            });


            TelemetryProvider.appInsights.addTelemetryInitializer((envelope) => {
                const baseData = envelope.baseData;
                if (envelope.name === RemoteDependencyData.envelopeType) {
                    if (baseData?.name && baseData.name.indexOf(".aptrinsic.com") > -1) {
                        return false; // do not ingest aptrinsic calls
                    }
                }
                return true;
            });

            TelemetryProvider.appInsights.loadAppInsights();

            botdLoad()
                .then((botd) => botd.detect())
                .then((result) => TelemetryProvider.botdValue = result)
                .catch((error) => console.error(error));

            TelemetryProvider.appInsights.addTelemetryInitializer((envelope) => {
                if (TelemetryProvider.botdValue) {
                    envelope.data = envelope.data || {};

                    envelope.data.isBot = TelemetryProvider.botdValue.bot.toString();
                    if (TelemetryProvider.botdValue.bot === true) {
                        envelope.data.botKind = TelemetryProvider.botdValue.botKind;
                    }
                }
            });
        }
        TelemetryProvider.appInsights.context.application.ver = props.appVersion || "0.0.0-dev";
    }

    render() {
        return (<>{this.props.children}</>);
    }
}

export default withAITracking(TelemetryProvider.reactPlugin, TelemetryProvider);
export const getAppInsights = () => {
    if (TelemetryProvider.appInsights)
        return TelemetryProvider.appInsights;
    else
        throw new Error("Application Insights not initialized.");
}
