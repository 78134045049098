import { useReactiveVar } from "@apollo/client";
import { Icon } from "@src/components/atoms/Icon";
import { Flex } from "@src/components/layout/Page";
import {
  ErrorHeader,
  ErrorMessage,
  ErrorMessageHeader,
  ReloadButton,
  StyledErrorBar,
  StyledErrorBarMessage,
} from "@src/components/styles";
import { useUrlParams } from "@src/customHooks";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { RequestError, ResponseVersionHash } from "@src/localVariables";
import { SigneeInfoRoute } from "@src/Routes";
import { getAppInsights } from "@src/TelemetryProvider";
import { useGoogleTranslate } from "@src/utils/translation";
import { GraphQLError } from "graphql";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

const ErrorBar: FC = () => {
  const { t, i18n } = useTranslation();
  const { baseUrl } = useUrlParams();
  const googleTranslate = useGoogleTranslate();
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const requestError = useReactiveVar(RequestError);
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const responseVersionId = responseVersionQuery.responseVersion?.responseVersionId;
  const errorHeader = requestError?.message?.includes("500") ? t("errors.500") : t("errors.errorHasOccurred");
  const appInsights = getAppInsights();
  const userId = appInsights.context?.user?.id;

  if (requestError?.message?.includes("Error 124")) {
    return <Navigate to={`${baseUrl}/${SigneeInfoRoute}`} />;
  }

  return requestError ? (
    <>
      <StyledErrorBar>
        <Flex justifyContent="flex-end">
          <Icon style="solid" icon="circle-xmark" color="#3d3d3d" onClick={() => { RequestError(null) }} />
        </Flex>
        <ErrorHeader>{errorHeader}</ErrorHeader>
        {requestError.message ? (
          <Flex>
            <ErrorMessageHeader>{googleTranslate("Message:")}</ErrorMessageHeader>
            <ErrorMessage>{googleTranslate(requestError.message)}</ErrorMessage>
          </Flex>
        ) : null}
        {requestError.message && !i18n.language.includes("en") ? (
          <Flex style={{ marginTop: "10px" }}>
            <ErrorMessageHeader>Message:</ErrorMessageHeader>
            <ErrorMessage>{requestError.message}</ErrorMessage>
          </Flex>
        ) : null}
        {requestError.message && responseVersionId ? (
          <Flex style={{ marginTop: "10px" }}>
            <ErrorMessageHeader>Response Version Id:</ErrorMessageHeader>
            <ErrorMessage>{responseVersionId}</ErrorMessage>
          </Flex>
        ) : null}
        {requestError.message && userId ? (
          <Flex style={{ marginTop: "10px" }}>
            <ErrorMessageHeader>Trace Id:</ErrorMessageHeader>
            <ErrorMessage>{userId}</ErrorMessage>
          </Flex>
        ) : null}
        {"graphQLErrors" in requestError && requestError.graphQLErrors
          ? requestError.graphQLErrors.map((x, index) => {
            return (
              <div key={index}>
                <StyledErrorBarMessage>{x.message}</StyledErrorBarMessage>
                {x.extensions.response && (
                  <StyledErrorBarMessage>Url: {x.extensions.response.url}</StyledErrorBarMessage>
                )}
              </div>
            );
          })
          : null}
        {"networkError" in requestError && requestError.networkError ? (
          <StyledErrorBarMessage>{requestError.networkError.message}</StyledErrorBarMessage>
        ) : null}
        {"clientErrors" in requestError && requestError.clientErrors
          ? requestError.clientErrors.map((x, index) => {
            return <StyledErrorBarMessage key={index}>{x.message}</StyledErrorBarMessage>;
          })
          : null}
        {"extensions" in requestError && requestError.extensions && displayGraphQLError(requestError)}

        <ReloadButton onClick={() => { window.location.reload() }}>{t("labels.reload")}</ReloadButton>
      </StyledErrorBar>
    </>
  ) : null;
};

const displayGraphQLError = (error: GraphQLError) => {
  if (error.extensions.code && error.extensions.response) {
    return (
      <>
        <StyledErrorBarMessage>{error.extensions.code}</StyledErrorBarMessage>
        {error.extensions.response?.body?.message && (
          <StyledErrorBarMessage>{error.extensions.response.body.message}</StyledErrorBarMessage>
        )}
        <StyledErrorBarMessage>Url: {error.extensions.response?.url}</StyledErrorBarMessage>
      </>
    );
  }
};

export { ErrorBar };
