import { ApolloError } from "@apollo/client";
import { Flex, PageWrapper } from "@src/components/layout/Page";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { StyledHeader, StyledLabel } from "@src/components/styles";
import { Breakpoints, useBreakPoints } from "@src/customHooks";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Logo } from "../../components/atoms/Logo";

type ErrorProps = { error: ApolloError | string };

type ErrorWrapperArgs = { screenSize: Breakpoints };

const StyledErrorWrapper = styled(Flex) <ErrorWrapperArgs>`
  margin-right: ${(props) => (props.screenSize === "desktop" ? `50px` : `25px`)};
  padding-left: 5vw;
`;

const ErrorComponent: FunctionComponent<ErrorProps> = ({ error }) => {
  const internalMessage = error instanceof ApolloError ? error.message : undefined;
  const isString = typeof error === "string";

  const message = isString ? error : undefined; // we should not show backend messages to the end user.

  const screenSize = useBreakPoints();

  const logoDimensions = screenSize === "smallScreen" ? "100px" : "200px";

  const { t } = useTranslation();

  let helpText = t("errors.generic");

  if (message?.includes("404") || message?.includes("Incorrect URL") || internalMessage?.includes("404")) {
    helpText = t("errors.404");
  } else if (message?.includes("500") || internalMessage?.includes("500")) {
    helpText = t("errors.500");
  }

  return (
    <PageWrapper>
      <Flex
        style={{ flexGrow: "1", width: "100vw", backgroundColor: "white" }}
        flexDirection="row"
        justifyContent="center"
      >
        <StyledErrorWrapper flexDirection="column" justifyContent="center" screenSize={screenSize}>
          {message && <StyledHeader style={{ marginBottom: "10px" }}>{message}</StyledHeader>}
          <StyledLabel>{helpText}</StyledLabel>
        </StyledErrorWrapper>
        <Flex alignItems="center" style={{ paddingRight: "5vw" }}>
          <Logo style="circle" width={logoDimensions} height={logoDimensions} />
        </Flex>
      </Flex>
      <PageFooter />
    </PageWrapper>
  );
};

export { ErrorComponent };
